<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    required: true
  },
  summary: {
    type: String,
    required: true
  },
  invert: {
    type: Boolean,
    default: false
  },
  centerTitle: {
    type: Boolean,
    default: true
  },
})
</script>

<template>
  <div :class="`section-header ${props.invert ? 'invert' : ''}`">
    <small>{{ props.subtitle }}</small>
    <h1 :style="{ marginInline: props.centerTitle ? 'auto' : undefined }">{{ props.title }}</h1>
    <div class="summary">
      <p>{{ props.summary }}</p>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/typography.pcss";
.section-header {
  max-width: 20rem;
  @mixin screen xs {
    max-width: 25rem;
  }
  @mixin screen sm {
    max-width: 30rem;
  }
}
small {
  @mixin typeface-regular;
  color: var(--color-rose-500);
  margin-bottom: 0.5rem;
  display: block;
}
h1 {
  @mixin typeface-h1;
  color: var(--color-black);
  margin-top: 1rem;
  margin-bottom: 2rem;
  max-width: 25rem;
}
p {
  @mixin typeface-summary;
  color: var(--color-slate-400);
  margin-top: 0;
  margin-bottom: 2.5rem;
  max-width: 30rem;
}

.invert {
  h1 {
    color: var(--color-white);
  }
  p {
    color: var(--color-slate-200);
  }
}
</style>
